
import moment from 'moment';
import { Component, Prop, Watch } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';
import Btn from '@/components/buttons/Btn/Btn.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import DatePicker from '@/components/form/DatePickerFilter.vue';
import ModalBlock from '@/components/Modal.vue';
import { GuiButton, GuiTextField, GuiFormCheckbox } from '@library/gigant_ui';

import ShopEntityModule from '@/store/shops/entity';
import ShopsModule from '@/store/shops';
import { strings } from '@/lib/stringConst';
import { getClientById, getClients } from '@/api/client';
import { searchAddress } from '@/api/geo';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getRegionsAll } from '@/api/region';

import YandexMap from '@/lib/map/yandexMap';

import IconFont from '@/components/icons/IconFont.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import FormChangeInfo from '@/components/data/FormChangeInfo.vue';
import { formatPhone } from '@/lib/Utils';
import Loading from '@/components/Loading.vue';
import { EventBus } from '@/lib/event-bus';
import UserModule from '@/store/user';
import { CustomerResponseModel } from '@/interfaces/models/customer.interface';
import { ShopModel, ShopTextFields, ShopComment } from '@/interfaces/models/shop.interface';
import { SelectForm } from '@/lib/formFactory/selectForm.interface';
import { AddressItem, regionEntity } from '@/interfaces/regionResponse.interface';
import { ClientPartners } from '@/interfaces/partner.interface';
import { FormResponse } from '@/interfaces/shared';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { reactive, Ref, ref, watch } from 'vue';
import { PaymentStrategies } from '@/lib/PaymentStrategies';
import { PartnerSelectItem } from '@/interfaces/ui/select.interface';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import { getAllPartnersList } from '@/api/partners';

let YANDEX_MAP: YandexMap = Object.create(null);

@Component({
  components: {
    FormMultiSelect,
    Loading,
    FormButton,
    FormChangeInfo,
    AppFieldset,
    AppCol,
    AppRow,
    Btn,
    IconFont,
    FormTextarea,
    FormInput,
    FormSelect,
    FormAutocomplete,
    DatePicker,
    GuiButton,
    GuiTextField,
    ModalBlock,
    GuiFormCheckbox,
  },
  emits: ['save', 'returnBefore', 'click'],
})
export default class ShopEntityForm extends FormEditor {
  @Prop() typeAction!: string;
  @Prop() view!: string;

  @Watch('selectedAddress')
  async addressStringChangedInModel(newValue: { address: string; latitude: number; longitude: number }): Promise<void> {
    if (newValue.address !== '' && this.canSaveNewAddressString) {
      this.address = { id: newValue.address, value: newValue.address };
      this.addressesItems = [
        {
          id: newValue.address,
          value: newValue.address,
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      ];

      await this.updateSelectedAddress({
        id: newValue.address,
        value: newValue.address,
        data: {
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      });
    }
  }

  @Watch('model.comments', { deep: true, immediate: true })
  sliceComments(_newValue?: ShopComment[], oldValue?: ShopComment[]): void {
    let count = this.shownComments.length < 5 ? 5 : this.shownComments.length;

    if (!oldValue && this.shownComments.length) {
      count += 5;
    }

    this.shownComments = this.model.comments.slice(0, count);
    this.editableComment = this.shownComments.find(({ user }) => user?.id === UserModule.id) ?? null;
  }

  @Watch('mainPartner.value', { deep: true })
  watcherPartners(_newValue?: PartnerSelectItem[]): void {
    this.setPaymentStrategyItems();
  }

  @Watch('customer', { deep: true })
  async watcherCustomer(_newValue?: SelectForm): Promise<void> {
    if (_newValue && _newValue.id) {
      this.partner = [];
      this.mainPartner.value = { id: '', value: '', name: '', isSupervisor: false };
      const result = await getClientById(_newValue.id);
      this.model.clientSubcontractors = result.clientSubcontractors;
      this.initPartners();
    }
  }

  canSaveNewAddressString = false;

  code = this.model.code ?? '';
  name = this.model.name ?? '';
  marketPhones = this.model.marketPhones.length ? this.model.marketPhones.slice(0) : [''];
  prepareMarketPhones = this.model.marketPhones.length ? this.model.marketPhones.slice(0) : [''];
  info = this.model.info ?? '';
  vacancies = this.model.vacancies ? this.model.vacancies.map((el: any) => el.name).join(', ') : '';
  isActive = this.model.isActive ?? false;
  regularNeed = this.model.regularNeed ?? false;
  isTest = this.model.isTest ?? false;
  isShared = this.model.isShared ?? false;

  customer: SelectForm = this.model.customer ?? { id: null, value: '' };
  customerItems: { id: string; value: string }[] = [];

  region = this.model.region ?? { id: null, value: '' };
  regionItems: { id: string; value: string }[] = [];

  partner: PartnerSelectItem[] = [];
  partnerItems: PartnerSelectItem[] = [];

  mainPartner: Ref<PartnerSelectItem> = ref({ id: '', value: '', name: '', isSupervisor: false });

  paymentStrategy: Ref<SelectItem> = ref({ id: '', value: '' });
  paymentStrategiesItems: SelectItem[] = reactive([]);

  address = this.model.address ?? { id: null, value: '' };

  addressesItems: AddressItem[] = [];
  addressLoading = false;

  responsibleName = this.model.responsibleName ?? '';
  responsiblePhone = this.model.responsiblePhone ?? '';
  responsiblePhoneModify = '';
  responsibleEmail = this.model.responsibleEmail ?? '';

  assistantLoading = false;
  assistant = this.model.assistant ?? { id: null, value: '' };
  assistantPhone = this.model.assistant.phone ?? '';
  assistantEmail = this.model.assistant.email ?? '';

  newCoordinates: { latitude: number; longitude: number } = ShopEntityModule.model.addressCoordinates.default;
  isShowTooltip = false;
  tooltipPosition = {};
  idTimeout: ReturnType<typeof setTimeout> = 0;
  isMapInit = false;
  isBlock = true;

  autoCheckIn = this.model.autoCheckIn ?? true;
  autoCheckup = this.model.autoCheckup ?? false;

  listsLoading = true;
  shopId = '';

  readonly commentMaxLength = 1024;
  readonly commentMinLength = 3;
  comment = '';
  shownComments: ShopComment[] = [];
  commentError = '';
  commentEditId = 0;
  isCommentAdd = false;
  isCommentEdit = false;
  isShownDeleteCommentModal = false;
  editableComment: ShopComment | null = null;

  isLoading = false;

  get isSupervisor(): boolean {
    return UserModule.isSupervisor;
  }

  get isShopOwner(): boolean {
    return ShopEntityModule.isShopOwner;
  }

  get isContractor(): boolean {
    return ShopEntityModule.isContractor;
  }

  get showLoadMoreCommentsButton(): boolean {
    return this.model.comments.length > this.shownComments.length;
  }

  get commentsTotalCount(): number {
    return this.model.comments.length;
  }

  get readonlyCustomer(): string {
    return String(ShopEntityModule.model.customer.value) ?? '';
  }

  get canEditShop(): boolean {
    const isOwnerOrSupervisor = this.isSupervisor || this.isShopOwner;

    return UserModule.userHasPermission('CAN_EDIT_CLIENT_MARKET') && isOwnerOrSupervisor;
  }

  get canManageCheckIn(): boolean {
    return UserModule.userHasPermission('CAN_MANAGE_CHECKIN');
  }

  get canChangeCustomer(): boolean {
    return !['shop_edit', 'customers_shop_add', 'customers_shop_edit'].includes(this.$route.name as string);
  }

  get checkoutCode(): string {
    return ShopEntityModule.checkoutCode;
  }

  get checkinCode(): string {
    return ShopEntityModule.checkinCode;
  }

  get checkInOutDate(): {
    key: string;
    type: string;
    title: string;
    value: string;
    action: string;
    placeholder: string;
    required: boolean;
    size: number;
    mask: { regex: string; placeholder: string; minChars: number };
    error: {};
    validation: {};
  } {
    return ShopEntityModule.checkInOutDate;
  }

  get assistantItems(): { id: string; email: string; phone: string; value: string }[] {
    return ShopEntityModule.assistantItems;
  }

  get isBlockMap(): boolean {
    return YANDEX_MAP.isBlock;
  }

  set isBlockMap(value: boolean) {
    this.isBlock = value;
    YANDEX_MAP.isBlock = value;
  }

  get isLoadingInfo(): boolean {
    return ShopEntityModule.isLoading;
  }

  get model(): ShopModel {
    return ShopEntityModule.model;
  }

  get text(): Record<string, string> {
    return strings;
  }

  get coordinates(): { latitude: number; longitude: number } {
    return ShopEntityModule.model.addressCoordinates.value as { latitude: number; longitude: number };
  }

  get selectedAddress(): {} {
    return ShopsModule.selectedAddress;
  }

  get preparePhones(): Record<string, string> {
    const phones: Record<string, string> = {};

    this.prepareMarketPhones
      .filter((phone: string) => {
        return phone;
      })
      .forEach((phone: string, index: number) => {
        phones[`phones[${index}]`] = phone;
      });

    return phones;
  }

  get timezone(): string {
    const timezoneNumber = this.region.timezoneNumber;
    if (typeof timezoneNumber !== 'undefined' && timezoneNumber !== '') {
      return `UTC+${timezoneNumber}`;
    }

    return '';
  }

  get textFieldsData(): ShopTextFields {
    const data: ShopTextFields = {
      code: this.code,
      name: this.name,
      comment: this.comment,
      info: this.info,
      address: this.address.value as string,
      region: this.region.id as string,
      isActive: this.isActive ? '1' : '0',
      regularNeed: this.regularNeed ? '1' : '0',
      responsibleName: this.responsibleName,
      responsiblePhone: this.responsiblePhoneModify,
      responsibleEmail: this.responsibleEmail,
      isTest: this.isTest ? '1' : '0',
      sharing: this.isShared ? 'all' : 'none',
      autoCheckIn: this.autoCheckIn || this.autoCheckup ? '1' : '0',
      autoCheckup: this.autoCheckup ? '1' : '0',
      mainSubcontractor: this.mainPartner.value.id,
    };

    if (this.partner && this.partner.length > 0) {
      data.subcontractors = this.partner.map((item) => item.id);
    }

    if (this.paymentStrategy && this.paymentStrategy.value && this.paymentStrategy.value.id) {
      data.paymentStrategy = this.paymentStrategy.value.id as string;
    }

    if (this.assistant && this.assistant.id) {
      data.assistant = this.assistant.id;
    }

    if (this.customer && this.customer.id) {
      data.customerId = this.customer.id as string;
    }

    return Object.assign(data, this.preparePhones);
  }

  selectDateCheckInOut(date: { day: string; month: string; year: string }): void {
    ShopEntityModule.updateCheckInOutDate(date);
    ShopEntityModule.getCheckInOutCodes(this.shopId as string);
  }

  startCommentAdd(): void {
    if (!this.isCommentAdd) {
      this.isCommentAdd = true;
      this.isCommentEdit = false;
      this.comment = '';
    }
  }

  startCommentEdit(id: number, text: string): void {
    this.isCommentAdd = false;
    this.isCommentEdit = true;
    this.commentEditId = id;
    this.comment = text;
  }

  disableCommentForm(): void {
    this.isCommentAdd = false;
    this.isCommentEdit = false;
    this.commentEditId = 0;
    this.comment = '';
    this.commentError = '';
  }

  formatCommentDate(date: string): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  showMoreComments(): void {
    this.sliceComments();
    this.disableCommentForm();
  }

  checkCommentIsValid(): boolean {
    let isValid = true;

    if (this.comment.length < this.commentMinLength) {
      if (!this.comment.length) {
        this.commentError = 'Поле не заполнено';
      } else {
        this.commentError = 'Минимальная длина комментария: 3 символа';
      }

      isValid = false;
    } else if (this.comment.length > this.commentMaxLength) {
      this.commentError = `Максимальная длина комментария: ${this.commentMaxLength} символа`;

      isValid = false;
    }

    return isValid;
  }

  getCommentCounterClass(count: number): string {
    const isPristineComment = this.typeAction === 'add' && !this.comment.length;

    return isPristineComment || count >= this.commentMinLength ? '' : 'cp-error-color';
  }

  async sendComment(): Promise<void> {
    if (!this.checkCommentIsValid()) {
      return;
    }

    if (this.commentEditId) {
      await ShopEntityModule.editComment({
        id: +this.commentEditId,
        text: this.comment,
      });
    } else {
      await ShopEntityModule.addNewComment(this.comment);
    }

    this.disableCommentForm();
  }

  async deleteComment(): Promise<void> {
    await ShopEntityModule.deleteComment(+this.commentEditId);

    this.isShownDeleteCommentModal = false;
    this.disableCommentForm();
  }

  async getRegions(): Promise<void> {
    try {
      const result: regionEntity[] = await getRegionsAll();

      this.regionItems = result.map((region: regionEntity) => {
        return {
          id: region.id,
          value: region.name,
          timezoneNumber: region.timezoneNumber,
        };
      });
    } catch (error) {
      this.regionItems = [];
    }
  }

  async getClients(): Promise<void> {
    try {
      const result = await getClients();

      this.customerItems = result.clients.map((client: CustomerResponseModel) => {
        return {
          id: client.id,
          value: client.name,
        };
      });
    } catch (error) {
      this.customerItems = [];
    }
  }

  async initPartners(): Promise<void> {
    try {
      const result = await getAllPartnersList();

      this.partnerItems = this.model.clientSubcontractors
        .filter((partner: ClientPartners) => partner.partner.isSupplier)
        .map(
          (partner: ClientPartners): PartnerSelectItem => {
            this.model.partner.filter((item) => {
              if (item.id === partner.partner.uuid && this.shopId) {
                this.partner.push({
                  id: partner.partner.uuid,
                  value: partner.partner.legalName,
                  name: partner.partner.legalName,
                  isSupervisor: result.filter((item) => item.uuid === partner.partner.uuid)[0]?.isSupervisor ?? false,
                });
              }
            });

            return {
              id: partner.partner.uuid,
              value: partner.partner.legalName,
              name: partner.partner.legalName,
              isSupervisor: result.filter((item) => item.uuid === partner.partner.uuid)[0]?.isSupervisor ?? false,
            };
          }
        );
      if (this.shopId) {
        this.mainPartner.value = this.model.mainPartner as PartnerSelectItem;
      }
    } catch (error) {
      this.partnerItems = [];
    }
  }

  async initMap(): Promise<void> {
    const mapWrapper = document.getElementById('map');

    if (!this.coordinates) {
      return;
    }

    if (!mapWrapper) {
      setTimeout(() => {
        this.initMap();
      }, 300);

      return;
    }

    const height = mapWrapper && mapWrapper.clientHeight >= 500 ? mapWrapper.clientHeight : 500;
    mapWrapper.style.height = `${height}px`;

    YANDEX_MAP = new YandexMap();
    YANDEX_MAP.initMap(this.coordinates, 'map');
    if (this.coordinates.latitude && this.coordinates.longitude) {
      await YANDEX_MAP.addPlaceMarker(this.coordinates);
    }

    this.isBlockMap = this.typeAction === 'edit';
    YANDEX_MAP.addEventsClick();

    this.isMapInit = true;
  }

  updateCustomer(selectedItem: { id: string }): void {
    if (selectedItem) {
      ShopEntityModule.getAssistantsList(selectedItem.id);
    }
  }

  updateAssistant(selectedItem: { phone: string; email: string }): void {
    if (selectedItem) {
      this.assistantPhone = formatPhone(selectedItem.phone) ?? '';
      this.assistantEmail = selectedItem.email ?? '';
    }
  }

  clickMap(e: { target: HTMLElement; layerY: string; layerX: string }): void {
    if (this.typeAction === 'add' || !this.isBlockMap) {
      return;
    }

    //
    // check if zoom btn clicked
    //
    if (!e.target.className.includes('zoom')) {
      this.showTooltip();
      this.tooltipPosition = {
        top: e.layerY + 'px',
        left: e.layerX + 'px',
      };
    }
  }

  clearTimeout(id: number): void {
    if (id) {
      clearTimeout(id);
    }
  }

  hideTooltip(): void {
    this.clearTimeout(this.idTimeout);

    this.isShowTooltip = false;
  }

  showTooltip(): void {
    this.clearTimeout(this.idTimeout);

    this.isShowTooltip = true;
    this.idTimeout = setTimeout(() => {
      this.isShowTooltip = false;
    }, 3000);
  }

  toggleBlockMap(): void {
    this.isBlockMap = !this.isBlock;
  }

  editCoordinate(): void {
    this.hideTooltip();
    this.toggleBlockMap();
  }

  saveCoordinate(): void {
    const newCoordinates = this.newCoordinates;
    this.updateCoordinates(newCoordinates);
    this.toggleBlockMap();
  }

  cancelCoordinate(): void {
    const oldCoordinates = this.coordinates;
    YANDEX_MAP.updatePlaceMarker([oldCoordinates.latitude, oldCoordinates.longitude]);
    this.toggleBlockMap();
  }

  returnBefore(): void {
    this.$emit('returnBefore');
  }

  deleteShop(): void {
    ShopsModule.deleteShopModal(this.shopId);
  }

  changeSelectedAddress(e: { target: { value: string } }): void {
    this.updateSelectedAddress({ value: e.target.value });
  }

  async updateSelectedAddress(selected: {
    id?: string;
    value: string;
    data?: { latitude: number; longitude: number };
  }): Promise<void> {
    if (!selected.value.trim().length) {
      return;
    }

    if (typeof selected.data !== 'undefined') {
      this.updatePointMap(selected.value, {
        lat: selected.data.latitude,
        lng: selected.data.longitude,
      });
      ShopEntityModule.updateAddressCoordinates({
        latitude: selected.data.latitude,
        longitude: selected.data.longitude,
      });
    }
  }

  updatePointMap(address: string, coordinates: { lat: number; lng: number }): void {
    YANDEX_MAP.updatePlaceMarker([coordinates.lat, coordinates.lng]);
    YANDEX_MAP.entity.setCenter([coordinates.lat, coordinates.lng]);
    YANDEX_MAP.setPlaceMarkAddress(address);
  }

  updateCoordinates(coordinates: { latitude: number; longitude: number }): void {
    this.canSaveNewAddressString = true;
    this.newCoordinates = { latitude: coordinates.latitude, longitude: coordinates.longitude };
    ShopEntityModule.updateAddressCoordinates(this.newCoordinates);
    ShopsModule.updateSelectedAddress(this.newCoordinates);
  }

  setNewCoordinate(event: { detail: number[] }): void {
    this.canSaveNewAddressString = false;
    this.newCoordinates = { latitude: event.detail[0], longitude: event.detail[1] };
    ShopsModule.updateSelectedAddress(this.newCoordinates);
  }

  addPhone(): void {
    this.marketPhones.push('');
    this.prepareMarketPhones.push('');
  }

  removePhone(index: number): void {
    this.marketPhones.splice(index, 1);
    this.prepareMarketPhones.splice(index, 1);
  }

  prepareResultAddressItems(addresses: { address: string; lat: number; long: number }[]): AddressItem[] {
    return addresses.map((address: { address: string; lat: number; long: number }) => {
      return {
        id: address.address,
        value: address.address,
        latitude: address.lat,
        longitude: address.long,
      };
    });
  }

  async searchAddress(value: string): Promise<void> {
    try {
      if (value.length < 3) {
        this.addressesItems = [];

        return;
      }

      this.addressLoading = true;

      const result = await searchAddress(value);

      this.addressesItems = this.prepareResultAddressItems(result);

      this.addressLoading = false;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  async add(): Promise<void> {
    try {
      this.isLoading = true;
      const result =
        this.view === 'customer'
          ? await ShopEntityModule.addNew({
              customerId: this.$route.params.customerId as string,
              textFieldsData: this.textFieldsData,
            })
          : await ShopEntityModule.addNew({ textFieldsData: this.textFieldsData });

      if (result && !(result as FormResponse).message) {
        this.returnBefore();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async edit(): Promise<void> {
    try {
      this.isLoading = true;
      const result = await ShopEntityModule.saveUpdate({
        id: this.shopId,
        textFieldsData: this.textFieldsData,
      });

      if (result && 'autoCheckIn' in result) {
        this.autoCheckIn = result.autoCheckIn;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async save(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.typeAction === 'add' && this.comment.length && !this.checkCommentIsValid()) {
        return;
      }

      return super.save();
    } finally {
      this.isLoading = false;
    }
  }

  copyCheckIn(): void {
    if (this.autoCheckIn) {
      navigator.clipboard
        .writeText(`${this.checkinCode}`.trim())
        .then(() => {
          ResponseHandlerModule.showNotify({ message: 'Код для check in скопирован', type: 'ok' });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  copyCheckOut(): void {
    if (this.autoCheckIn) {
      navigator.clipboard
        .writeText(`${this.checkoutCode}`.trim())
        .then(() => {
          ResponseHandlerModule.showNotify({ message: 'Код для check out скопирован', type: 'ok' });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  openMarkdownModal(): void {
    EventBus.$emit('showModal', { name: 'markdown', flag: true });
  }

  created(): void {
    document.body.addEventListener(
      'yandexMapClick',
      this.typeAction === 'edit'
        ? (this.setNewCoordinate as any)
        : (event: any) => {
            this.updateCoordinates({ latitude: event.detail[0] as number, longitude: event.detail[1] as number });
          }
    );
  }

  paymentStrategies: PaymentStrategies | null = null;

  initPartnerChangedWatch(): void {
    watch(this.mainPartner, (newVal?: Ref<PartnerSelectItem>) => {
      if (this.paymentStrategies !== null) {
        this.paymentStrategiesItems = this.paymentStrategies.getSelectItemsForPartner(newVal?.value);
        this.paymentStrategy.value = this.paymentStrategies.getSelectItemForPartner(newVal?.value);
      }
    });
  }

  async initPaymentStrategies(): Promise<void> {
    this.paymentStrategies = new PaymentStrategies();
    await this.paymentStrategies.init();

    this.setPaymentStrategyItems();

    this.initPartnerChangedWatch();
  }

  setPaymentStrategyItems() {
    this.paymentStrategies = new PaymentStrategies();
    this.paymentStrategiesItems = [];
    const mainPartnerTmp = this.partnerItems.filter((item) => item.id === this.mainPartner.value?.id)[0];
    if (mainPartnerTmp) {
      this.paymentStrategiesItems = this.paymentStrategies.getSelectItemsForPartner(mainPartnerTmp);
    } else {
      this.paymentStrategiesItems = this.paymentStrategies.getSelectItems();
    }

    if (this.model.paymentStrategy) {
      this.paymentStrategy.value = this.paymentStrategiesItems.find((item) => item.id == this.model.paymentStrategy) ?? {
        id: '',
        value: '',
      };
    }
  }

  async mounted(): Promise<void> {
    this.shopId = this.$route.params.shopId as string;

    await this.initMap();

    if (this.canChangeCustomer) {
      await this.getClients();
    }

    if (this.canEditShop) {
      await this.getRegions();
    }

    if (this.isSupervisor || this.isShopOwner || this.isContractor) {
      if (this.shopId || this.model.customer.id) {
        await this.initPartners();
      }
      await this.initPaymentStrategies();
    }

    this.listsLoading = false;
  }
}
