import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-change-info" }
const _hoisted_2 = {
  key: 0,
  class: "form-change-info__item"
}
const _hoisted_3 = {
  key: 1,
  class: "form-change-info__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.createdAt)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, "Дата создания " + _toDisplayString(_ctx.format(_ctx.createdAt)), 1))
      : _createCommentVNode("", true),
    (_ctx.updatedAt)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, "Посл. изменение " + _toDisplayString(_ctx.format(_ctx.updatedAt)), 1))
      : _createCommentVNode("", true)
  ]))
}