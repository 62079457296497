
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'FormChangeInfo',
  props: {
    updatedAt: { type: String },
    createdAt: { type: String },
  },
  methods: {
    format(date: string) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
  },
});
